<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <div class="flex justify-content-end flex-col-reverse lg:flex-row">
            <router-link :to="{name: 'AdminRoleAdd'}"
                         class="whitespace-nowrap text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5">
                <path fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                      clip-rule="evenodd"></path>
              </svg>
              Thêm mới
            </router-link>
          </div>

          <div class="lg:mt-8 mt-2">
            <table class="w-full border-collapse">
              <thead>
              <tr class="border-b border-slate-200">
                <th class="py-2 pr-2">Tên ban</th>
                <th class="py-2 pr-2">Alias</th>
                <th class="py-2 pr-2">Số thành viên</th>
                <th class="py-2 pr-2">Thứ tự</th>
                <th class="py-2 pr-2">Ban/nhóm trong tên?</th>
                <th class="py-2 pr-2">Ban/nhóm</th>
                <th class="py-2 pr-2">Thao tác</th>
              </tr>
              </thead>
              <tbody class="divide-y" v-if="roles.data && roles.data.length">
              <tr v-for="(role, index) in roles.data" :key="'roles' + index">
                <td>
                  <router-link class="font-semibold" :to="{name: 'AdminRoleEdit', params: {id: role.id}}">
                    {{ role.name }}
                  </router-link>
                </td>
                <td>{{ role.alias }}</td>
                <td>{{ role.userCount }}</td>
                <td>{{ role.position }}</td>
                <td>{{ role.included_name }}</td>
                <td>{{ role.group_type }}</td>
                <td class="p-2 whitespace-nowrap">
                  <router-link :to="{name: 'AdminRoleEdit', params: {id: role.id}}">
                    Sửa
                  </router-link>
                  <router-link :to="{name: 'RoleDetail', params: {slug: role.slug}}">
                    Xem
                  </router-link>
                  <a href="#" uk-toggle="target: #addMemberModal" v-on:click="setCurrentRole(role)">
                    Thêm
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div id="addMemberModal" class="create-post is-story" uk-modal>
      <div
          class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">Thêm thành viên "{{ currentRole.name }}"</h3>
          <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close
                  uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>

        <div class="px-5 py-2">
          <form v-on:submit.prevent="addUserRole">
            <div class="col-span-2 mb-3">
              <label class="font-semibold">Thành viên</label>
              <multiselect
                  v-model="user"
                  valueProp="id"
                  label="username"
                  trackBy="username"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchUsers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.username
                  }}
                </template>

                <template v-slot:singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    <img class="w-7 h-7 rounded-full object-cover mr-3"
                         :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                      value.username
                    }}
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="col-span-2 mb-3">
              <label class="font-semibold">Vị trí trong ban/nhóm?</label>
              <multiselect id="sub_role" v-model="sub_role" :options="subRoleOptions"></multiselect>
            </div>
            <div class="col-span-2 mb-3">
              <label for="custom_title" class="font-semibold">Danh hiệu riêng</label>
              <input v-model="custom_title" class="shadow-none with-border" id="custom_title" type="text"
                     autocomplete="off">
            </div>
            <div class="col-span-2 mb-3">
              <label for="position" class="font-semibold">Thứ tự</label>
              <input v-model="position" class="shadow-none with-border" id="position" min="0" type="number"
                     autocomplete="off">
            </div>
            <div class="col-span-2 mb-3">
              <label class="font-semibold">Là nhóm chính?</label>
              <multiselect id="sub_role" v-model="is_primary" :options="isPrimaryOptions"></multiselect>
            </div>
            <div class="flex w-full border-t p-3 pl-0">
              <button type="submit"
                      class="bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
                Thêm thành viên
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import ApiService from "../../../core/services/api.service";
import {changePageTitle, isAdmin} from "../../../core/services/utils.service";

export default {
  name: "AdminRoleList",
  data() {
    return {
      roles: {
        data: []
      },
      user: null,
      currentRole: {
        name: ""
      },
      subRoleOptions: {
        admin: "Trưởng",
        vice_admin: "Phó"
      },
      isPrimaryOptions: {
        1: "Chính",
        0: "Phụ"
      },
      sub_role: null,
      custom_title: null,
      position: null,
      is_primary: 1
    }
  },
  methods: {
    loadRoles() {
      let query = `query($page: Int) {
        roles(first:100, page: $page, orderBy: [{column: "position", order:ASC}]) {
          data {
            id
            name
            slug
            alias
            position
            included_name
            group_type
            userCount
          }
        }
      }`;

      ApiService.graphql(query, {page: 1})
          .then(({data}) => {
            if (data.data && data.data.roles) {
              this.roles = data.data.roles;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    addUserRole() {
      let query = `mutation($user_id: ID!, $role_id: ID!, $sub_role: String, $custom_title: String, $position: Int, $is_primary: Int) {
        addUserRole(
          user_id: $user_id,
          role_id: $role_id,
          sub_role: $sub_role
          custom_title: $custom_title
          position: $position
          is_primary: $is_primary
        ) {id}
      }`;

      ApiService.graphql(query, {
        user_id: this.user.id,
        role_id: this.currentRole.id,
        sub_role: this.sub_role,
        custom_title: this.custom_title,
        position: parseInt(this.position),
        is_primary: parseInt(this.is_primary)
      })
          .then(({data}) => {
            if (data.data && data.data.addUserRole) {
              this.$toast.success("Thêm thành viên thành công");
              this.user = null;
              this.custom_title = null;
              this.position = null;
              this.is_primary = null;
              this.reRender();
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    setCurrentRole(role) {
      this.currentRole = role;
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    reRender() {
      this.loadRoles();
    }
  },
  mounted() {
    if (!isAdmin()) {
      this.$router.push({name: "Home"});
      return;
    }
    this.reRender();
    changePageTitle("Quản lý ban");
  }
}
</script>
